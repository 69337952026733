import { Environment, Firebase } from '.';
import { Address, Purchase } from '@humancollective/seedz-shared';

export const post = async ({
  url,
  data = {},
  authenticate = false,
}: {
  url: string;
  data: any;
  authenticate?: boolean;
}) => {
  // In case we need to modify the params
  let params = { ...data };

  // if we need to autheticate the request
  if (authenticate) {
    // Get the currently signed in users id token
    const idToken = await Firebase.getUserIdToken();
    if (idToken) {
      // Add it to the params if it exists
      params = { ...params, idToken };
    }
  }
  const request = await fetch(`${Environment.API_URL}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(params),
  });
  const json = await request.json();
  return json;
};

export const get = async (url: string) => {
  const response = await fetch(`${Environment.API_URL}${url}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const json = await response.json();
  return json;
};

interface CalculateTotalArgs {
  address: Address;
  numberOfCredits: number;
}

export const calculateTotal = async ({
  address,
  numberOfCredits,
}: CalculateTotalArgs) => {
  const request = await post({
    url: '/api/calculateTotal',
    data: {
      address,
      amountOfCredits: Number(numberOfCredits),
    },
  });
  if (request.status !== 'success') throw new Error(request.message);
  return request;
};

interface PurchaseCreditsArgs {
  purchase: Purchase;
  businessId: string;
}

export const purchaseCredits = async ({
  purchase,
  businessId,
}: PurchaseCreditsArgs) => {
  // transform number of credits to a number
  const nextPurchase = {
    ...purchase,
    numberOfCredits: Number(purchase.numberOfCredits),
  };

  const request = await post({
    url: '/api/purchaseCredits',
    data: { businessId, purchase: nextPurchase },
  });
  if (request.status !== 'success') throw new Error('something went wrong');
  return request;
};
