import React from 'react';
import { App } from '@humancollective/human-dashboard';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import {
  createThemeColor,
  injectThemeColors,
  Theme,
  overrideTextStyles,
  createThemeColorWithVariants,
} from '@humancollective/uikit-web';
import './style.css';

import {
  IsUserAdminContextProvider,
  ProfileContextProvider,
  UIContextProvider,
  UserBusinessContextProvider,
} from '../contexts';
import { Environment } from '../utilities';

const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .layout__contents {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }
`;

overrideTextStyles({
  h1: {
    weight: 600,
    size: 80,
    lineHeight: 90,
  },
  h2: {
    weight: 600,
    size: 40,
    lineHeight: 50,
  },
  h3: {
    weight: 600,
    size: 32,
    lineHeight: 40,
  },
  h4: {
    weight: 600,
    size: 24,
    lineHeight: 30,
  },
  h5: {
    size: 16,
    weight: 600,
    lineHeight: 20,
  },
  h6: {
    size: 10,
    lineHeight: 16,
  },
  p: {
    size: 16,
    lineHeight: 20,
  },
  span: {
    size: 12,
    lineHeight: 15,
    variant: 'secondary',
  },
});

// @ts-ignore
// Ignore the rest of the theme missing, the theme will override the missing values
injectThemeColors({
  primary: createThemeColor({ light: '#6DB54C', dark: '#65b144' }),
  secondary: createThemeColor({ light: '#003539', dark: '#003539' }),
  text: createThemeColorWithVariants({
    primary: {
      light: '#003539',
      dark: 'black',
    },
    secondary: {
      light: '#323232',
      dark: 'black',
    },
    disabled: {
      light: 'grey',
      dark: 'black',
    },
    error: {
      light: 'red',
      dark: 'black',
    },
    success: {
      light: 'green',
      dark: 'black',
    },
  }),
  background: createThemeColor({ light: '#FFFFFF', dark: '#000000' }),
  backgroundSecondary: createThemeColor({ light: '#F6F8FA', dark: '#fbfcfd' }),
});

console.log(
  `Seedz Dashboard - Running in ${
    Environment.isDevelopment ? 'development' : 'production'
  } ${Environment.isRunningLocally ? '(locally)' : ''}`
);

const PageLayout: React.FunctionComponent = ({ children }) => (
  <Theme
    defaults={{ mode: 'light', layout: 'small' }}
    applyGlobalStyles={false}
  >
    <App>
      <UIContextProvider>
        <IsUserAdminContextProvider>
          <ProfileContextProvider>
            <UserBusinessContextProvider>
              <StyledLayoutContainer>
                <Helmet>
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans"
                    rel="stylesheet"
                  />
                </Helmet>
                <div className="layout__contents">{children}</div>
              </StyledLayoutContainer>
            </UserBusinessContextProvider>
          </ProfileContextProvider>
        </IsUserAdminContextProvider>
      </UIContextProvider>
    </App>
  </Theme>
);

export default PageLayout;
