import * as React from 'react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

export interface ModalAction {
  text: string;
  onClick: () => void;
  intent: Intent;
}

export interface ShowModalProps {
  heading?: string;
  body?: React.ReactElement | string;
  actions?: ModalAction[];
  onModalClosing?: () => void;
}

export interface DefaultModalProps extends ShowModalProps {
  open: boolean;
}

export interface ModalProps extends DefaultModalProps {
  dismiss: () => void;
}

export type ModalType = DefaultModalProps & {
  show: (props: ShowModalProps) => void;
  dismiss: () => void;
};

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  heading,
  body,
  actions = [],
  dismiss,
  onModalClosing,
}) => {
  return (
    <Dialog isOpen={open} onClose={dismiss} onClosing={onModalClosing}>
      {heading && (
        <div className="bp3-dialog-header">
          <h4 className="bp3-heading">{heading}</h4>
          <button
            aria-label="Close"
            className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
            onClick={dismiss}
          />
        </div>
      )}
      <div className="bp3-dialog-body">{body}</div>
      {actions.length > 0 && (
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            {actions.map(action => (
              <Button
                key={action.text}
                text={action.text}
                intent={action.intent}
                onClick={action.onClick}
              />
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default Modal;
