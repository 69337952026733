export const isDevelopment: boolean =
  process.env.NODE_ENV !== 'production' &&
  process.env.GATSBY_ACTIVE_ENV !== 'production';

export const isRunningLocally: boolean =
  process.env.GATSBY_ACTIVE_ENV === 'local';

export const IMAGE_URL = process.env.GATSBY_IMGIX_URL;

export const API_URL = process.env.GATSBY_GCF_URL;
