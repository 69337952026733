import { DocumentSnapshot } from '@firebase/firestore-types';
import React from 'react';
import { FirebaseContext } from './';

const defaultValue = false;

export const IsUserAdminContext = React.createContext(defaultValue);

export const IsUserAdminContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState<boolean>(false);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase, firebaseUser } = React.useContext(
    FirebaseContext
  );

  React.useEffect(() => {
    if (isLoggedIn && firebaseUser) {
      const off = firebase
        .firestore()
        .collection('admins')
        .doc(firebaseUser.uid)
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [isLoggedIn]);

  const onUpdate = (doc: DocumentSnapshot) => {
    setValue(doc.exists);
  };

  return (
    <IsUserAdminContext.Provider value={value}>
      {children}
    </IsUserAdminContext.Provider>
  );
};
