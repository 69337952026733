import React from 'react';
import { IToastProps, Toaster } from '@blueprintjs/core';

import Modal, {
  DefaultModalProps,
  ModalType,
  ShowModalProps,
} from '../../components/Modal';
import Loader, { LoaderProps } from '../../components/Loader';

const defaultValues = {
  showToast: (toast: IToastProps) => {},
  modal: {
    open: false,
    show: () => {},
    dismiss: () => {},
  },
  loader: {
    open: false,
    show: () => {},
    dismiss: () => {},
  },
};

export interface UIContext {
  showToast: (toast: IToastProps) => void;
  modal: ModalType;
  loader: LoaderProps & {
    show: (message?: string) => void;
    dismiss: () => void;
  };
}

export const UIContext = React.createContext<UIContext>(defaultValues);

export const UIContextProvider: React.FunctionComponent = ({ children }) => {
  const [modalState, setModalState] = React.useState<DefaultModalProps>({
    open: false,
  });
  const [loaderState, setLoaderState] = React.useState<LoaderProps>({
    open: false,
  });

  const toastRef = React.useRef<Toaster>(null);

  const showToast = (toast: IToastProps) => {
    const toaster: Toaster | null = toastRef.current;
    if (toaster) {
      toast.timeout = 3000;
      toaster.show(toast);
    }
  };

  const showModal = ({
    actions,
    heading,
    body,
    onModalClosing,
  }: ShowModalProps) => {
    setModalState({ heading, body, actions, onModalClosing, open: true });
  };

  const dismissModal = () => {
    setModalState({ open: false });
  };

  const showLoader = (message?: string) => {
    setLoaderState({ message, open: true });
  };

  const dismissLoader = () => {
    setLoaderState({ open: false, message: undefined });
  };

  return (
    <UIContext.Provider
      value={{
        showToast,
        modal: { ...modalState, show: showModal, dismiss: dismissModal },
        loader: {
          ...loaderState,
          show: showLoader,
          dismiss: dismissLoader,
        },
      }}
    >
      <Toaster ref={toastRef} />
      <Modal {...modalState} dismiss={dismissModal} />
      <Loader {...loaderState} />
      {children}
    </UIContext.Provider>
  );
};
