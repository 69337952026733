import * as React from 'react';
import { FirebaseContext } from './';
import { DocumentSnapshot } from '@firebase/firestore-types';
import { Business } from '@humancollective/seedz-shared';
import { ProfileContext } from './Profile';

type UserBusiness = Business | undefined;

export const UserBusinessContext = React.createContext<UserBusiness>(undefined);

export const UserBusinessContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState<UserBusiness>(undefined);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { firebase } = React.useContext(FirebaseContext);

  const profile = React.useContext(ProfileContext);
  const businessId = profile && profile.business;

  React.useEffect(() => {
    if (businessId) {
      const off = firebase
        .firestore()
        .collection('businesses')
        .doc(businessId)
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [businessId]);

  const onUpdate = (doc: DocumentSnapshot) => {
    const docData = doc.data() as Business;
    setValue(docData);
  };

  return (
    <UserBusinessContext.Provider value={value}>
      {children}
    </UserBusinessContext.Provider>
  );
};
