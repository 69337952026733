import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { groupBy } from 'lodash';
import moment from 'moment';
import {
  Business,
  Campaign,
  FirestoreCollections,
  Location,
  Seed,
  Token,
} from '@humancollective/seedz-shared';

import * as user from './Firebase/user';
import * as zone from './Firebase/zone';

export { user, zone };

export const getLocationAnalytics = async ({
  locationId,
}: {
  locationId: string;
}) => {
  const STATS_WINDOW_DAYS = 30;
  const trackingDocs = await firebase
    .firestore()
    .collection('track')
    .where('locationId', '==', locationId)
    .where('action', '==', 'ViewLocation')
    .get();
  const tracking = trackingDocs.docs.map(t => t.data());
  const total = tracking.length;
  const uniques = Object.keys(groupBy(tracking, 'userId')).length;
  const callsByDay = groupBy(tracking, ({ date }) =>
    Number(
      moment
        .unix(date.seconds)
        .endOf('day')
        .toDate()
        .getTime()
    )
  );
  const endMoment = moment().add(1, 'days');
  const startMoment = endMoment.clone().subtract(STATS_WINDOW_DAYS, 'days');

  const dailyCalls = [];
  for (let i = 0; i < STATS_WINDOW_DAYS; i += 1) {
    const currentMoment = startMoment
      .clone()
      .add(i, 'days')
      .endOf('day');
    const calls = callsByDay[currentMoment.toDate().getTime()] || [];
    dailyCalls.push({
      label: currentMoment.format('MM/DD'),
      value: calls.length,
    });
  }

  return { total, uniques, dailyCalls };
};

export const getCampaignAnalytics = async ({
  campaignId,
}: {
  campaignId: string;
}) => {
  const STATS_WINDOW_DAYS = 30;
  const trackingDocs = await firebase
    .firestore()
    .collection('track')
    .where('campaignId', '==', campaignId)
    .get();
  const tracking = trackingDocs.docs.map(t => t.data());

  const totalEvents = tracking.length;

  const users = Object.keys(groupBy(tracking, 'userId')).length;
  const callsByDay = groupBy(tracking, ({ date }) =>
    Number(
      moment
        .unix(date.seconds)
        .endOf('day')
        .toDate()
        .getTime()
    )
  );
  const endMoment = moment().add(1, 'days');
  const startMoment = endMoment.clone().subtract(STATS_WINDOW_DAYS, 'days');

  const dailyCalls = [];
  for (let i = 0; i < STATS_WINDOW_DAYS; i += 1) {
    const currentMoment = startMoment
      .clone()
      .add(i, 'days')
      .endOf('day');
    const calls = callsByDay[currentMoment.toDate().getTime()] || [];
    dailyCalls.push({
      label: currentMoment.format('MM/DD'),
      total: calls.length,
      unlockEvents: calls.filter(t => t.action === 'CreateToken').length,
      consumeEvents: calls.filter(t => t.action === 'ConsumeToken').length,
      redeemEvents: calls.filter(t => t.action === 'RedeemToken').length,
      saveEvents: calls.filter(t => t.action === 'SaveToken').length,
    });
  }

  return { totalEvents, users, dailyCalls };
};

export const getLocation = async (
  id: string
): Promise<Location | undefined> => {
  const locationDoc = await firebase
    .firestore()
    .collection(FirestoreCollections.ENVIRONMENT)
    .doc(FirestoreCollections.PROD_WEB)
    .collection(FirestoreCollections.LOCATIONS)
    .doc(id)
    .get();
  return locationDoc.exists
    ? { ...(locationDoc.data() as Location) }
    : undefined;
};

export const addLocation = async (data: any = {}) => {
  const locationRef = await firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('locations')
    .add(data);
  return { ...data, id: locationRef.id };
};

export const removeLocation = async (id: string) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('locations')
    .doc(id)
    .delete();

export const saveLocation = (id: string, values: any) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('locations')
    .doc(id)
    .set({ ...values }, { merge: true });

export const addCampaign = async (data: any = {}) => {
  const campaignRef = await firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('campaigns')
    .add(data);
  return { ...data, id: campaignRef.id };
};

export const removeCampaign = (id: string) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('campaigns')
    .doc(id)
    .delete();

export const saveCampaign = (id: string, values: any) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('campaigns')
    .doc(id)
    .set({ ...values, markerImage: '/utils/seedz-icon.png' }, { merge: true });

export const getCampaignData = async (id: string) => {
  const campaign = await firebase
    .firestore()
    .collection('builder')
    .doc(id)
    .get();
  return campaign.data() || {};
};

export const getCampaign = async (
  id: string
): Promise<Campaign | undefined> => {
  const campaignDoc = await firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('campaigns')
    .doc(id)
    .get();
  return campaignDoc.exists
    ? { ...(campaignDoc.data() as Campaign) }
    : undefined;
};

export const saveCampaignData = (id: string, values: any) =>
  firebase
    .firestore()
    .collection('builder')
    .doc(id)
    .set({ ...values }, { merge: true });

export const removeSeed = (id: string) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('seedz')
    .doc(id)
    .delete();

export const addSeed = (seed: Seed) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('seedz')
    .add({
      ...seed,
      markerImage: '/utils/seedz-icon.png',
    });

export const setSeed = (id: string, seed: Seed) =>
  firebase
    .firestore()
    .collection('environment')
    .doc('PROD-WEB')
    .collection('seedz')
    .doc(id)
    .set({
      ...seed,
    });

export const getToken = async (id: string): Promise<Token | undefined> => {
  const tokenDoc = await firebase
    .firestore()
    .collection('tokens')
    .doc(id)
    .get();
  return tokenDoc.exists ? { ...(tokenDoc.data() as Token) } : undefined;
};

export const getUserIdToken = async (): Promise<string | undefined> => {
  try {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      return idToken;
    }
    return undefined;
  } catch (error) {
    console.log('Error creating endpoint auth token', error);
  }
};

export const saveBusiness = async (business: Business) =>
  firebase
    .firestore()
    .collection(FirestoreCollections.BUSINESSES)
    .doc(business.id)
    .set({ ...business }, { merge: true });

export const updateBusinessById = async (businessId: string, business: any) =>
  firebase
    .firestore()
    .collection(FirestoreCollections.BUSINESSES)
    .doc(businessId)
    .set({ ...business }, { merge: true });

export const setUserBusiness = async (id: string) => {
  const { uid } = firebase.auth().currentUser;
  firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .update({ business: id });
};
