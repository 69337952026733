import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '@blueprintjs/core';
import posed, { PoseGroup } from 'react-pose';

import { Colors } from '../../config';

const AnimatedContainer = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const Container = styled(AnimatedContainer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .bp3-spinner-head {
    stroke: ${Colors.PRIMARY};
  }
`;

const Text = styled.span`
  font-size: 24px;
  color: black;
  text-align: center;
  margin-top: 10px;
  color: ${Colors.SECONDARY};
  font-weight: bold;
`;

export interface LoaderProps {
  open: boolean;
  message?: string;
}

const Loader: React.FunctionComponent<LoaderProps> = ({ open, message }) => (
  <PoseGroup>
    {open && (
      <Container key="loader">
        <Spinner />
        {message && <Text>{message}</Text>}
      </Container>
    )}
  </PoseGroup>
);

export default Loader;
