export * from './region';

export enum Colors {
  PRIMARY = '#65b144',
  SECONDARY = '#003539',
  PRIMARY_LIGHT = '#a5ca8c',
  BP_BACKGROUND = '#fbfcfd',
  RED = '#FF0000',
}

export enum Routes {
  MAP = '/map',
  BUSINESS = '/business',
  REGISTER = '/register',
}

export const DEFAULT_MARKER_IMAGE_PATH = '/utils/seedz-icon.png';
