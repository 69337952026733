import React from 'react';
import { FirestoreCollections, Location } from '@humancollective/seedz-shared';
import { QuerySnapshot } from '@firebase/firestore-types';

import { FirebaseContext } from './';
import { ProfileContext } from './Profile';
import { IsUserAdminContext } from './IsUserAdmin';

const defaultValue = null as Location[] | null;

export const UserLocationsContext = React.createContext(defaultValue);

export const UserLocationsContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase } = React.useContext(FirebaseContext);
  const profile = React.useContext(ProfileContext);
  const isAdmin = React.useContext(IsUserAdminContext);

  React.useEffect(() => {
    if (isLoggedIn && profile) {
      const locationsRef = firebase
        .firestore()
        .collection(FirestoreCollections.ENVIRONMENT)
        .doc(FirestoreCollections.PROD_WEB)
        .collection(FirestoreCollections.LOCATIONS);
      // if the user is an admin, let them have access to all businesses
      const filteredLocationsRef = isAdmin
        ? locationsRef
        : locationsRef.where('owner', '==', profile.business || '');
      const off = filteredLocationsRef.onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [isLoggedIn, profile, isAdmin]);

  const onUpdate = (snapshot: QuerySnapshot) => {
    setValue(
      snapshot.docs.map(doc => ({
        ...(doc.data() as Location),
        id: doc.id,
      }))
    );
  };

  return (
    <UserLocationsContext.Provider value={value}>
      {children}
    </UserLocationsContext.Provider>
  );
};
