import React from 'react';
import { FirebaseContext } from './';
import { QuerySnapshot } from '@firebase/firestore-types';
import { Seed } from '@humancollective/seedz-shared';

const defaultValue = [] as Seed[];

export const SeedzContext = React.createContext(defaultValue);

export const SeedzContextProvider: React.FunctionComponent = ({ children }) => {
  const [value, setValue] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    if (isLoggedIn) {
      const off = firebase
        .firestore()
        .collection('environment')
        .doc('PROD-WEB')
        .collection('seedz')
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [isLoggedIn]);

  const onUpdate = (querySnap: QuerySnapshot) => {
    setValue(
      querySnap.docs.map(seedDoc => {
        const seed = seedDoc.data() as Seed;
        return {
          markerImage: '/utils/seedz-icon.png',
          ...seed,
          id: seedDoc.id,
        } as Seed;
      })
    );
  };

  return (
    <SeedzContext.Provider value={value}>{children}</SeedzContext.Provider>
  );
};
