import * as firebase from 'firebase/app';
import { FirestoreCollections, Zone } from '@humancollective/seedz-shared';

export const get = async (zoneId: string): Promise<Zone | undefined> => {
  const zoneDoc = await firebase
    .firestore()
    .collection(FirestoreCollections.ZONES)
    .doc(zoneId)
    .get();

  return zoneDoc.exists ? { ...(zoneDoc.data() as Zone) } : undefined;
};

export const set = async (zone: Zone) => {
  await firebase
    .firestore()
    .collection(FirestoreCollections.ZONES)
    .doc(zone.id)
    .set(zone);
};

export const remove = async (zoneId: string) => {
  await firebase
    .firestore()
    .collection(FirestoreCollections.ZONES)
    .doc(zoneId)
    .delete();
};
