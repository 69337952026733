import { Zone, Position } from '@humancollective/seedz-shared';
import geolib from 'geolib';

const isPositionInZone = ({
  zones,
  position,
}: {
  zones: Zone[];
  position: Position;
}): Zone | undefined => {
  const zonesMarkerIsInsideOf = zones.filter(zone =>
    geolib.isPointInside(position, zone.bounds)
  );

  return zonesMarkerIsInsideOf.length > 0
    ? zonesMarkerIsInsideOf[0]
    : undefined;
};

export default isPositionInZone;
