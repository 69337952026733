import React from 'react';
import { FirebaseContext } from './';
import { QuerySnapshot } from '@firebase/firestore-types';
import { Business } from '@humancollective/seedz-shared';

const defaultValue = [] as Business[];

export const BusinessesContext = React.createContext(defaultValue);

export const BusinessesContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    listener.unsubscribe();
    if (isLoggedIn) {
      const off = firebase
        .firestore()
        .collection('businesses')
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
  }, [isLoggedIn]);

  const onUpdate = (querySnap: QuerySnapshot) => {
    setValue(
      querySnap.docs.map(doc => {
        const docData = doc.data() as Business;
        return {
          ...docData,
          id: doc.id,
        } as Business;
      })
    );
  };

  return (
    <BusinessesContext.Provider value={value}>
      {children}
    </BusinessesContext.Provider>
  );
};
