import * as firebase from 'firebase/app';
import { addMonths, format, addDays, subMonths, subDays } from 'date-fns';

export type Timestamp = firebase.firestore.Timestamp;

export const timestampToDate = (timestamp: Timestamp): Date =>
  timestamp.toDate();

export const dateToTimestamp = (date: Date): Timestamp =>
  firebase.firestore.Timestamp.fromDate(date);

export const now = (): Timestamp =>
  firebase.firestore && firebase.firestore.Timestamp.now();

export const toFormatted = (date: Date) =>
  date.toLocaleDateString('us-EN', {
    hour: '2-digit',
    minute: '2-digit',
  });

export const formatBillingDate = (date: Timestamp): string => {
  const timestampInDate = timestampToDate(date);
  return format(timestampInDate, 'MMM Do, YYYY');
};

export const addMonthToTimestamp = (timestamp: Timestamp): Timestamp => {
  const timestampInDate = timestampToDate(timestamp);
  const dateWithAddedMonth = addMonths(timestampInDate, 1);
  return dateToTimestamp(dateWithAddedMonth);
};

export const addTimeperiodToTimestamp = (
  timestamp: Timestamp,
  { months = 0, days = 0 }: { months?: number; days?: number }
): Timestamp => {
  let timestampInDate = timestampToDate(timestamp);

  // Months
  timestampInDate =
    months > 0
      ? addMonths(timestampInDate, months)
      : subMonths(timestampInDate, Math.abs(months));

  // Days
  timestampInDate =
    days > 0
      ? addDays(timestampInDate, days)
      : subDays(timestampInDate, Math.abs(days));

  return dateToTimestamp(timestampInDate);
};

export const isTimestampOlderThanNow = (timestamp: Timestamp): boolean =>
  now().toMillis() > timestamp.toMillis();

export const areTimestampsEqual = (
  firstTimestamp: Timestamp,
  secondTimestamp: Timestamp
): boolean => firstTimestamp.isEqual(secondTimestamp);
