import React from 'react';
import { FirebaseContext } from './';
import { QuerySnapshot } from '@firebase/firestore-types';
import { ProfileContext } from './Profile';
import { Campaign } from '@humancollective/seedz-shared';
import { IsUserAdminContext } from './IsUserAdmin';

const defaultValue = [] as Campaign[];

export const UserCampaignsContext = React.createContext(defaultValue);

export const UserCampaignsContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase } = React.useContext(FirebaseContext);
  const profile = React.useContext(ProfileContext);
  const isAdmin = React.useContext(IsUserAdminContext);

  React.useEffect(() => {
    if (isLoggedIn && profile) {
      const campaignsRef = firebase
        .firestore()
        .collection('environment')
        .doc('PROD-WEB')
        .collection('campaigns');
      const filteredCampaignsRef = isAdmin
        ? campaignsRef
        : campaignsRef.where('business', '==', profile.business || '');
      const off = filteredCampaignsRef.onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [isLoggedIn, profile, isAdmin]);

  const onUpdate = (querySnap: QuerySnapshot) => {
    setValue(
      querySnap.docs.map(doc => {
        const docData = doc.data() as Campaign;
        return {
          ...docData,
          id: doc.id,
        } as Campaign;
      })
    );
  };

  return (
    <UserCampaignsContext.Provider value={value}>
      {children}
    </UserCampaignsContext.Provider>
  );
};
