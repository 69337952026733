import * as React from 'react';
import { FirebaseContext } from './';
import { DocumentSnapshot } from '@firebase/firestore-types';
import { Profile } from '@humancollective/seedz-shared';

const defaultValue = null as Profile | null;

export const ProfileContext = React.createContext(defaultValue);

export const ProfileContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase, firebaseUser } = React.useContext(
    FirebaseContext
  );

  React.useEffect(() => {
    if (isLoggedIn && firebaseUser) {
      const off = firebase
        .firestore()
        .collection('users')
        .doc(firebaseUser.uid)
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
    listener.unsubscribe();
  }, [isLoggedIn]);

  const onUpdate = (doc: DocumentSnapshot) => {
    const docData = doc.data() as Profile;
    setValue(docData);
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};
