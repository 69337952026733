import * as firebase from 'firebase/app';
import 'firebase/auth';

import { UserCredential } from '@firebase/auth-types';
import { FirestoreCollections, Profile } from '@humancollective/seedz-shared';

export const register = async (
  email: string,
  password: string
): Promise<UserCredential> =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const set = async (user: any) => {
  // ideally this would limit to any keys in user.
  await firebase
    .firestore()
    .collection(FirestoreCollections.USERS)
    .doc(user.id)
    .set(user, { merge: true });
};

export const updateCurrentUser = async (user: any) => {
  // ideally this would limit to any keys in user.
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error("Can't update current user if not logged in.");
  }
  await firebase
    .firestore()
    .collection(FirestoreCollections.USERS)
    .doc(currentUser.uid)
    .set(user, { merge: true });
};
