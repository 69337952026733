import * as React from 'react';
import { FirestoreCollections, Zone } from '@humancollective/seedz-shared';
import { FirebaseContext } from './';
import { QuerySnapshot } from '@firebase/firestore-types';

const defaultValue = [] as Zone[];

export const ZonesContext = React.createContext(defaultValue);

export const ZonesContextProvider: React.FunctionComponent = ({ children }) => {
  const [zones, setZones] = React.useState(defaultValue);
  const [listener, setListener] = React.useState({ unsubscribe: () => {} });
  const { isLoggedIn, firebase } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    listener.unsubscribe();
    if (isLoggedIn) {
      const off = firebase
        .firestore()
        .collection(FirestoreCollections.ZONES)
        .onSnapshot(onUpdate);
      setListener({ unsubscribe: off });
      return off;
    }
  }, [isLoggedIn]);

  const onUpdate = (querySnap: QuerySnapshot) => {
    setZones(
      querySnap.docs.map(zoneDoc => ({
        ...(zoneDoc.data() as Zone),
      }))
    );
  };

  return (
    <ZonesContext.Provider value={zones}>{children}</ZonesContext.Provider>
  );
};
