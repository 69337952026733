import React from 'react';
import {
  Campaign,
  Location,
  Position,
  Zone,
} from '@humancollective/seedz-shared';

export interface SelectedMapItem {
  type: 'location' | 'campaign' | 'zone';
  data: Campaign | Location | Zone;
}

export interface MapContext {
  defaultCenter: Position; // Holds the default center for the map
  selectedItem?: SelectedMapItem; // Holds the current selected marker
  selectedPosition?: Position; // Holds the current position selected on the map (lat/lon)
  removeSelectedItem: () => void;
  setSelectedItem: (item: SelectedMapItem) => void;
  removeSelectedPosition: () => void;
  setSelectedPosition: (position: Position) => void;
  setDefaultCenter: (position: Position) => void;
}

const defaultValues: MapContext = {
  defaultCenter: {
    latitude: 0,
    longitude: 0,
  },
  removeSelectedItem: () => {},
  removeSelectedPosition: () => {},
  setSelectedItem: () => {},
  setSelectedPosition: () => {},
  setDefaultCenter: () => {},
};

export const MapContext = React.createContext<MapContext>(defaultValues);

interface MapContextParams {
  searchParams: {
    id?: string;
    type?: string;
  };
}

// This context is used for sharing map data across the project
// Currently, it holds the lat/lng position when the user right clicks the map and sets the location
export const MapContextProvider: React.FunctionComponent<MapContextParams> = ({
  children,
  searchParams,
}) => {
  const [selectedItem, setSelectedItem] = React.useState<
    undefined | SelectedMapItem
  >(undefined);

  const [selectedPosition, setSelectedPosition] = React.useState<
    undefined | Position
  >(undefined);

  const [defaultCenter, setDefaultCenter] = React.useState<Position>(
    defaultValues.defaultCenter
  );

  return (
    <MapContext.Provider
      value={{
        defaultCenter,
        selectedItem,
        setSelectedItem,
        selectedPosition,
        setSelectedPosition,
        setDefaultCenter,
        removeSelectedItem: () => setSelectedItem(undefined),
        removeSelectedPosition: () => setSelectedPosition(undefined),
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
