import ImgixClient from 'imgix-core-js';
import { IMAGE_URL } from './environment';

const imgixClient = new ImgixClient({ domains: IMAGE_URL });

export interface ImgixParams {
  mask?: 'CORNERS';
  background?: string;
  auto?: 'COMPRESS';
  fit?: 'CLAMP' | 'CROP' | 'FILL';
  dpr?: string;
  height?: number;
  width?: number;
  cornerRadius?: number;
  format?: 'png' | 'jpg';
}

export const createUrl = (
  url: string = '',
  {
    mask,
    background = 'ffffff',
    auto = 'COMPRESS',
    fit,
    dpr = '3',
    height = 50,
    width = 50,
    cornerRadius,
    format = 'png',
  }: ImgixParams
): string => {
  const imageUrl: string = imgixClient.buildURL(url, {
    dpr,
    h: height,
    w: width,
    bg: background,
    auto: auto.toLowerCase(),
    ...(mask && { mask: mask.toLowerCase() }),
    ...(fit && { fit: fit.toLowerCase() }),
    ...(cornerRadius && { 'corner-radius': cornerRadius }),
    fm: format,
  });

  return imageUrl;
};
